import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import Lottie from "lottie-react";

import Header from "../Layouts/Header";
import HeaderNew from '../Layouts/HeaderNew'
import Footer from "../Layouts/Footer";

import dropDownIcon from "../Assets/airdrop/dropdown.svg";
import skyImg from "../Assets/airdrop/sky.svg";
import airdropJson from "../Assets/Json/airdrop_new.json";
import darkuniverse from "../Assets/Json/comet.json";
import faqIndiOne from "../Assets/airdrop/faqindicator.svg";
import faqIndiTwo from "../Assets/airdrop/faqindi2.svg";
import axios from "axios";

import ConnectWallet from "../Modals/ConnectWallet";
import { WalletConnect, BinanceWallet, MetamaskWallet, claim, Claimed } from "../Hooks/useWallet";
import { } from "../Hooks/UseSolana"
import configurl from "../../src/config/config"
import { CHAINS } from "../config/env"
import { solana } from "../config/config"

import { useramount } from "../config/config"

import { UseAdminTransferSOLtokens } from "../Hooks/UseSolana"
const optionsOne = [
  { value: "network1", label: "Network One" },
  { value: "network2", label: "Network Two" },
  { value: "network3", label: "Network Three" },
];
const optionsTwo = [
  { value: "token1", label: "Token One" },
  { value: "token2", label: "Token Two" },
  { value: "token3", label: "Token Three" },
];



const faqData = [
  {
    id: 1,
    title: "How does this work?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce volutpat vehicula scelerisque. Praesent sem velit, malesuada et tempus at, dignissim vitae leo. Cras sed efficitur libero. Etiam in lacus in enim mattis suscipit. Sed quis dictum libero. Aliquam auctor magna metus,.",
  },
  {
    id: 2,
    title: "Are there rate limits?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce volutpat vehicula scelerisque. Praesent sem velit, malesuada et tempus at, dignissim vitae leo. Cras sed efficitur libero. Etiam in lacus in enim mattis suscipit. Sed quis dictum libero. Aliquam auctor magna metus,.",
  },
  {
    id: 3,
    title: "What if it doesn't work?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce volutpat vehicula scelerisque. Praesent sem velit, malesuada et tempus at, dignissim vitae leo. Cras sed efficitur libero. Etiam in lacus in enim mattis suscipit. Sed quis dictum libero. Aliquam auctor magna metus,.",
  },
  {
    id: 4,
    title: "How to create a Dapp?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce volutpat vehicula scelerisque. Praesent sem velit, malesuada et tempus at, dignissim vitae leo. Cras sed efficitur libero. Etiam in lacus in enim mattis suscipit. Sed quis dictum libero. Aliquam auctor magna metus,.",
  },
  {
    id: 5,
    title: "How to create ERC-721?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce volutpat vehicula scelerisque. Praesent sem velit, malesuada et tempus at, dignissim vitae leo. Cras sed efficitur libero. Etiam in lacus in enim mattis suscipit. Sed quis dictum libero. Aliquam auctor magna metus,.",
  },
  {
    id: 6,
    title: "How  can i get bulk test Tokens?",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce volutpat vehicula scelerisque. Praesent sem velit, malesuada et tempus at, dignissim vitae leo. Cras sed efficitur libero. Etiam in lacus in enim mattis suscipit. Sed quis dictum libero. Aliquam auctor magna metus,.",
  },
];

export default function Airdrops() {
  // modal

  const [showConnectWallet, setShowConnectWallet] = useState(false);
  const [WalletAddress, setWalletAddress] = useState('');
  const handleConnectWalletShow = async () => {
    setShowConnectWallet(true);

    // var address = localStorage.getItem("Solanawallet")
    //       axios.post(configurl.backurl+"/api/userReg",address).then(res=>{



    //       })

  };

  const handleConnectWalletHide = () => {
    setShowConnectWallet(false);
  };
  const stylesgraybg = {
    option: (styles, { isFocused, isSelected, isHovered }) => {
      return {
        ...styles,
        backgroundColor: isHovered
          ? "#a26702"
          : isSelected
            ? "#a26702"
            : isFocused
              ? "#a26702"
              : "",
        cursor: "pointer",
        color: isHovered
          ? "#fff"
          : isSelected
            ? "#ffffff"
            : isFocused
              ? "#ffffff"
              : "",
        fontSize: "13px",
        zIndex: 1,
      };
    },
    valueContainer: (provided, state) => ({
      ...provided,
      height: "45px",
      // padding: "0 10px",
      paddingLeft: "20px",

      // width: "100%",
      backgroundColor: "#232323",
      color: "#ffffff",
      border: "none",
      borderRadius: 0,
      fontSize: "16px",
    }),
    control: (provided, state) => ({
      ...provided,
      height: "45px",
      borderRadius: 10,
      backgroundColor: "#fff",
      border: "none",
      outline: "none",
      boxShadow: "none",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "45px",
      position: "absolute",
      right: 0,
      top: 0,
      color: "#ffba00",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#D6D6D6",
    }),
    menuList: (base) => ({
      ...base,
      // kill the white space on first and last option
      padding: 0,
      border: "1px solid #a26702",
      borderRadius: 0,
      background: "#232323",
    }),

    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: "#D6D6D6",
        // paddingLeft: "10px",
      };
    },
  };

  const [CmsAirdrop, setCmsAirdrop] = useState([])
  const [Chain, setChain] = useState([])
  const [token, setToken] = useState([])
  
  const [Activedata, setActive] = useState()
console.log("Activedata_Activedata",Activedata);
  const [wallet, setShowWallet] = useState(true)
  const [walletcount, setWalletcount] = useState(0);
  const [accountInfo, setAccountInfo] = useState("");
  const [walletModal, set_walletModal] = useState(true)
  const [walletConnect, set_walletConnect] = useState("")
  const [user, setUser] = useState("")
  const [userclaimed, setuserclaimed] = useState();

  const [claimed, setClaimed] = useState()

  const [claimeddatas, setClaimeddatas] = useState()


  const getAlldata = async () => {

    axios.get(configurl.backurl + "/api/GetCmsAirdrop").then(res => {

      setCmsAirdrop(res.data)


    })
  }


  const claimedusers = async () => {

    try {



      var to = localStorage.getItem("Solanawallet")

      var data = {
        to: to,
        solana: solana,
        useramount: useramount
      }
      let userdata = await axios.post(configurl.backurl + "/api/UseAdminTransferSOL", data)
      if (userdata.data.status == false) {
        setuserclaimed(userdata.data.message)
      }

    } catch (error) {
      console.log("claimed_error", error);
    }
  }


  const getsettings = async()=>{

    let usersetting = await axios.get(configurl.backurl + "/api/GetSettingdata")

    if(usersetting.data.data){
      console.log("usersetting_usersetting123",usersetting.data.data.status);
      setActive(usersetting.data.data.status)

    }
  }
  
// const userApprove = async()=>{
//   var data ={
// address:   localStorage.Solanawallet}

//   let usersetting = await axios.get(configurl.backurl + "/api/userApprove",data)

  
// }


  const MetaMask = async () => {
    await MetamaskWallet()
    // await sleep(1000)
    // await useWeb3();
    setAccountInfo(localStorage.getItem("accountInfo"))
    console.log(accountInfo, "Wallet  Address........")
    if (localStorage.accountInfo) {
      setShowWallet(false)
      // toast.success("Wallet Connected successfully", {
      //     duration: 2000,
      //     style: {
      //         minWidth: '300px',
      //         minHeight: '55px'
      //     }
      // })
      window.location.reload()
      // await sleep(1000).then(() => {

      //     window.location.reload()

      // })
    }
  }

  const claimeddata = async () => {
    console.log("dataaaaa");
    var data = await Claimed()
    setClaimed((data) / 10 ** 18)
    // setBlanace(data)
  }


  // const Userinfromation = async () => {

  //   axios
  //       .post(configurl.backurl + "/api/userinfromation")
  //       .then(res => {
  //           // set_records(res.data)

  //       })
  //       .catch()
  // };
  const getUserDetails = async () => {
    try {
      const payload = { walletAddress : localStorage.getItem("Solanawallet")}
      let userDetails = await axios.post(configurl.backurl + "/api/getSingleDetails", payload);
      console.log("getUserDetails_userDetails",userDetails)
      // if(userDetails){
        setUser(userDetails?.data?.data)
      // }
    } catch (e) {
      console.log("getUserDetails_err", e);
    }
  }

  useEffect(() => {
    console.log('localStorage',localStorage.Solanawallet)
    if (localStorage.Solanawallet) {
      setShowWallet(false)
      getUserDetails()
    }
  }, [WalletAddress])

  useEffect(() => {
    getAlldata()
    getsettings()
    // Userinfromation()
    claimeddata()
  }, []);

  useEffect(() => {
    let arr = []
    let token = []
    CHAINS.map((index, i) => {
      let obj = {
        value: index.NAME,
        label: index.NAME
      }
      arr.push(obj)
      let val = {
        value: index.TOKENADDRESS,
        label: index.TOKENADDRESS
      }
      token.push(val)
    })

    setChain(arr)
    setToken(
      [
        {
          value: localStorage.getItem('Solanawallet'),
          label: localStorage.getItem('Solanawallet')
        }
      ])
  }, [CHAINS])

  return (
    <>
      <Header />
      {/* <HeaderNew/> */}

      {/* <div className="h-100vh d-flex justify-content-center align-items-center">
        <h1>Coming Soon..</h1>
      </div> */}

      <div className="ad_inner">
        <Container className="container_90">
          <section className="ad_bnr_sec py-5">
            <Row className="d-flex flex-column-reverse flex-md-row ">
              <Col md={6} className="d-flex align-items-center mt-5 mt-md-0">
                <div className="position-relative">
                  <Lottie
                    className="position-absolute ad_strs_json"
                    animationData={darkuniverse}
                    loop={true}
                  />
                  <h1 className="text-uppercase ad_ban_tle">{`${CmsAirdrop[0]?.identifier == "Airdrop" ? CmsAirdrop[0]?.content : ""}`}</h1>
                  <p className="ad_ban_subtle">
                    {/* is a visionary project that encompasses the creation of a
                    decentralized digital ecosystem comprising{" "}
                    <span>Dark Coin</span> and a revolutionary MetaVerse called
                    Dark MetaVerse. */}
                    {`${CmsAirdrop[1]?.identifier == "Airdrop" ? CmsAirdrop[1]?.content : ""}`}
                  </p>
                </div>
              </Col>
              <Col
                md={6}
                className="d-flex align-items-center position-relative"
              >
                <Lottie
                  className="position-absolute  ad_strs_json2"
                  animationData={darkuniverse}
                  loop={true}
                />
                <Lottie className=" " animationData={airdropJson} loop={true} />
              </Col>
            </Row>
          </section>
          <section className="py-5 ">
            <Row className="justify-content-center">
              <Col sm={11} lg={9} xl={9} xxl={7}>
                <div className="ad_data_dls py-4">
                  <Row className="mx-auto flex-column flex-sm-row">
                    {/* <Col className="d-flex justify-content-center my-4 my-sm-0 ad_data_col">
                      <div>
                        <p className="text-center ad_data_wht_p">
                        {`${CmsAirdrop[2]?.identifier == "Airdrop" ? CmsAirdrop[2]?.content : <></>}`}
                        </p>
                        <p className="text-center ad-data_orng_p mb-sm-0">
                          1000
                        </p>
                      </div>
                    </Col> */}
                    <Col className="d-flex justify-content-center ad_data_col">
                      <div>
                        <p className="text-center ad_data_wht_p">
                          {`${CmsAirdrop[3]?.identifier == "Airdrop" ? CmsAirdrop[3]?.content : ""}`}
                        </p>
                        <p className="text-center ad-data_orng_p mb-sm-0">{user?.amount ? user?.amount : 0}</p>
                      </div>
                    </Col>
                    {/* <Col className="d-flex justify-content-center my-4 my-sm-0">
                      <div>
                        <p className="text-center ad_data_wht_p">{`${CmsAirdrop[4]?.identifier == "Airdrop" ? CmsAirdrop[4]?.content : <></>}`}</p>
                        <p className="text-center ad-data_orng_p mb-0">100</p>
                      </div>
                    </Col> */}
                  </Row>
                </div>
                <div className="ad_form_crd mt-5">
                  <h1>{`${CmsAirdrop[5]?.identifier == "Airdrop" ? CmsAirdrop[5]?.content : ""}`}</h1>
                  <p className="ad_form_content">
                    {`${CmsAirdrop[6]?.identifier == "Airdrop" ? CmsAirdrop[6]?.content : ""}`}
                  </p>

                  <div>
                    {/* <label className="mt-3">Network</label>
                    <div className="cust_react_slt position-relative mt-2">
                      <div className="slt_tri_one position-absolute"></div>
                      <div className="slt_tri_two position-absolute"></div>
                     
                      <Select
                        options={optionsOne}
                        placeholder="Select Network"
                        styles={stylesgraybg}
                      />
                    </div> */}
                    <label className="mt-4">Address</label>
                    {/* <div className="cust_react_slt position-relative mt-2">
                      <div className="slt_tri_one position-absolute"></div>
                      <div className="slt_tri_two position-absolute"></div>
                      <div className="rct_slt_icn position-absolute">
                        <img src={dropDownIcon} alt="" className="img-fluid" />
                      </div>
                      <Select
                        menuIsOpen={true}
                        options={token}
                        defaultValue={localStorage.getItem("Solanawallet")}
                        value={localStorage.getItem("Solanawallet")}
                        placeholder="Select Address"
                        styles={stylesgraybg}
                      />

                    </div> */}

                    <div className="cust_react_slt position-relative mt-2 nrml_inpt_bg">
                      <div className="slt_tri_one position-absolute"></div>
                      <div className="slt_tri_two position-absolute"></div>
                      {/* <div className="rct_slt_icn position-absolute">
                        <img src={dropDownIcon} alt="" className="img-fluid" />
                      </div> */}
                      <p className=" m-0 wallet_address_wrap">{localStorage.getItem("Solanawallet")}</p>

                    </div>

                    {/* <label className="mt-4"> Wallet Address</label>

                    <div className="ad_wlt_address mt-2 position-relative">
                      <div className="slt_tri_one position-absolute"></div>
                      <div className="slt_tri_two position-absolute"></div>
                      <Row className="mx-auto h-100">
                        <Col className="ps-0" xs={7} sm={9} md={10}>
                          <input
                            type="text"
                            placeholder="0xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                          />
                        </Col>
                        <Col
                          xs={5}
                          sm={3}
                          md={2}
                          className="d-flex align-items-center justify-content-end "
                        >
                          <button>Paste</button>
                        </Col>
                      </Row>
                    </div>

                    <button className="ad_cont_btn mt-4 mt-sm-5 ">
                      Continue
                    </button>

                    <div className="ad_or_border position-relative d-flex align-items-center justify-content-center mt-2 mt-sm-3 mt-md-4">
                      OR
                    </div> */}
                    {/* <button
                      className="ad_connect_btn mt-4 mt-lg-5"
                      onClick={handleConnectWalletShow}
                    >
                      Connect Wallet
                    </button> */}
                    {/* {wallet == true ? */}

                    {Activedata == "Activate" ?
                      user?.status == true || user?.status == "true" ?
                        <p className="text-center mt-4 text-danger">ClaimSed !</p>
                        :
                        <button className="ad_connect_btn mt-4 mt-lg-5" onClick={() => { handleConnectWalletShow() }} >
                          Request Airdrop
                        </button> :
                      <p className="text-center mt-4 text-danger">Airdrop campaign closed. Thanks!</p>
                    }

                      {/* :
                      <button className="ad_connect_btn mt-4 mt-lg-5" onClick={() => { claimedusers() }} >
                        Claim
                      </button> */}
                      {/* } */}

                    {/* {<button className= "ad_connect_btn mt-4 mt-lg-5"  onClick={()=>{
                     let claimed = UseAdminTransferSOLtokens(solana,localStorage.getItem('Solanawallet'),useramount)
                     setClaimeddatas(claimed)
                      }} >
                      Claim
                    </button> } */}
                  </div>
                  <p className="error_msg_red text-danger text-center mt-3">{userclaimed}</p>
                </div>

                <Row className="ad_faq_row position-relative mt-5 mx-auto">
                  <div className="ad_faq_ind_one position-absolute">
                    <img src={faqIndiOne} alt="faq" className="img-fluid" />
                  </div>
                  <div className="ad_faq_ind_two position-absolute">
                    <img src={faqIndiTwo} alt="faq" className="img-fluid" />
                  </div>

                  <Col xs={12} sm={6} xl={6} className="mb-2  px-0">
                    <div className="ad_faq_card_one">
                      <h5 className="ad_faq_tle mt-2">{`${CmsAirdrop[7]?.identifier == "Airdrop" ? CmsAirdrop[7]?.content : ""}`}</h5>
                      <p className="ad_faq_subtle mt-4">
                        {`${CmsAirdrop[8]?.identifier == "Airdrop" ? CmsAirdrop[8]?.content : ""}`}
                      </p>
                    </div>
                  </Col>
                  <Col xs={12} sm={6} xl={6} className="mb-2  px-0">
                    <div className="ad_faq_card_two">
                      <h5 className="ad_faq_tle mt-2">
                        {`${CmsAirdrop[9]?.identifier == "Airdrop" ? CmsAirdrop[9]?.content : ""}`}
                      </h5>
                      <p className="ad_faq_subtle mt-4">
                        {`${CmsAirdrop[10]?.identifier == "Airdrop" ? CmsAirdrop[10]?.content : ""}`}
                      </p>
                    </div>
                  </Col>
                  {/* <Col xs={12} sm={6} xl={4} className="mb-2  px-0">
                    <div className="ad_faq_card_three">
                      <h5 className="ad_faq_tle mt-2">
                      {`${CmsAirdrop[11]?.identifier == "Airdrop" ? CmsAirdrop[11]?.content : ""}`}
                      </h5>
                      <p className="ad_faq_subtle mt-4">
                      {`${CmsAirdrop[12]?.identifier == "Airdrop" ? CmsAirdrop[12]?.content : ""}`}
                      </p>
                    </div>
                  </Col> */}
                  {/* <Col xs={12} sm={6} xl={4} className="mb-2  px-0">
                    <div className="ad_faq_card_four">
                      <h5 className="ad_faq_tle mt-2">{`${CmsAirdrop[13]?.identifier == "Airdrop" ? CmsAirdrop[13]?.content : ""}`}</h5>
                      <p className="ad_faq_subtle mt-4">
                      {`${CmsAirdrop[14]?.identifier == "Airdrop" ? CmsAirdrop[14]?.content : ""}`}
                      </p>
                    </div>
                  </Col> */}
                  {/* <Col xs={12} sm={6} xl={4} className="mb-2  px-0">
                    <div className="ad_faq_card_five">
                      <h5 className="ad_faq_tle mt-2">
                      {`${CmsAirdrop[15]?.identifier == "Airdrop" ? CmsAirdrop[15]?.content : ""}`}
                      </h5>
                      <p className="ad_faq_subtle mt-4">
                      {`${CmsAirdrop[16]?.identifier == "Airdrop" ? CmsAirdrop[16]?.content : ""}`}
                      </p>
                    </div>
                  </Col> */}
                  {/* <Col xs={12} sm={6} xl={4} className="mb-2  px-0">
                    <div className="ad_faq_card_six">
                      <h5 className="ad_faq_tle mt-2">
                      {`${CmsAirdrop[17]?.identifier == "Airdrop" ? CmsAirdrop[17]?.content : ""}`}
                      </h5>
                      <p className="ad_faq_subtle mt-4">
                      {`${CmsAirdrop[18]?.identifier == "Airdrop" ? CmsAirdrop[18]?.content : ""}`}
                      </p>
                    </div>
                  </Col> */}
                </Row>
              </Col>
            </Row>
          </section>
        </Container>
        <Container fluid className="px-0 mt-5">
          <img src={skyImg} alt="" className="img-fluid" />
        </Container>
      </div>
      <Footer />
      <ConnectWallet
        showConnectWallet={showConnectWallet}
        setShowConnectWallet={setShowConnectWallet}
        setWalletAddress={setWalletAddress}
      />
    </>
  );
}
