import React, { useState } from "react";
import { useEffect } from 'react';
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';

import Validator from "validator";
import isEmpty from "is-empty";
import configurl from "../config/config"
import { Modal, Button, Row, Col } from "react-bootstrap";

import walletconnect from "../Assets/homepage/walletconnect.png";
import phantom from "../Assets/homepage/phantom.png";

import twitter from "../Assets/socialmedia/twitter.png";
import fb from "../Assets/socialmedia/fb.png";
import disc from "../Assets/socialmedia/discard.png";


import { IoMdClose } from "react-icons/io";
import { isWalletConnected } from "../Hooks/UseSolana";

const ConnectWallet = ({ showConnectWallet, setShowConnectWallet, setWalletAddress }) => {

  console.log("setShowConnectWallet_setShowConnectWallet", setWalletAddress);
  const [Sociallink, setCmsSociallink] = useState([]);
  console.log("cscsdcsd", Sociallink);
  const [Twitter, setTwitter] = useState();
  const [Telegram, setTelegram] = useState();
  const [Discord, setDiscord] = useState();
  const [wallet, setShowWallet] = useState(true)
  // const [Localdata, setLocaldata] = useState();

  // console.log("Localdata_Localdata",Localdata);
  const [errorTwitter, seterrorTwitter] = useState();
  const [errorTelegram, seterrorTelegram] = useState();
  const [errorDiscord, seterrorDiscord] = useState();


  const [steps, setSteps] = useState(1);

  const onchange = (e) => {

    if (e.target.id == 'Twitter')
      setTwitter(e.target.value)

    if (e.target.id == 'Telegram')
      setTelegram(e.target.value)

    if (e.target.id == 'Discord')
      setDiscord(e.target.value)


  };


  const getsociallink = async () => {
    axios.get(configurl.backurl + "/api/GetCmsSocialLink").then((res) => {
      setCmsSociallink(res.data);
    });
  };



  const userdata = async () => {

    try {
      var data = {
        Twitter: Twitter,
        Telegram: Telegram,
        Discord: Discord
      }
      let errors = {};
      data.Twitter = !isEmpty(data.Twitter) ? data.Twitter : "";
      data.Telegram = !isEmpty(data.Telegram) ? data.Telegram : "";
      data.Discord = !isEmpty(data.Discord) ? data.Discord : "";
      if (Validator.isEmpty(data.Twitter)) {
        errors.Twitter = "Twitter field is required";
        seterrorTwitter("Twitter field is required")
      }
      if (Validator.isEmpty(data.Telegram)) {
        errors.Telegram = "Telegram field is required";
        seterrorTelegram("Telegram field is required")
      }
      if (Validator.isEmpty(data.Discord)) {
        errors.Discord = "Discord field is required";
        seterrorDiscord("Discord field is required")
      }
      if (!isEmpty(errors)) {
        return false
      }
      // let userdatas = await axios.post(configurl.backurl + "/api/Userdata", data)
      // .then(res => {
      setSteps(steps + 1)


      // })
      // console.log("userdatas_userdatas", userdatas);

    } catch (error) {

      console.log("errroooo", error);
      // var errorTwitter = error.response.data.Twitter

      // var Telegrams = error.response.data.Telegram

      // var Discords = error.response.data.Discord

      // setSteps(steps + 1)

    }
  }


  const useralldatas = async () => {
    try {
      var data = {
        Twitter: Twitter,
        Telegram: Telegram,
        Discord: Discord,
        solana: localStorage.getItem("Solanawallet")
      }

      let userdatas = await axios.post(configurl.backurl + "/api/Userdata", data)
      //  console.log("userdatas_userdatas",userdatas.data.message);
      if (userdatas.data.message) {
        console.log("userdatas_userdatas", userdatas.data.message);
        setShowConnectWallet(false)
        toast.success(`${userdatas.data.message}`, {
          style: {
            minWidth: '300px',
            minHeight: '55px'
          }
        })
      }

    } catch (error) {

    }
  }



  //   var localStoragedata = localStorage.getItem("Solanawallet")
  // setLocaldata(localStoragedata)

  useEffect(() => {
    // userdata()
    getsociallink()
    // claimeddata()


  }, []);
  useEffect(() => {
    if (!isEmpty(localStorage.getItem('Solanawallet'))) {
      setSteps(steps + 1)
    }
  }, [])


  return (
    <div>
      <Modal
        size="md"
        centered
        show={showConnectWallet}
        onHide={setShowConnectWallet}
        backdrop="static"
        keyboard={false}
        className="mdl_cnt_wlt"
      >
        <Modal.Body>
          <div className="modal_header">
            <h5 className="mdl_head_h1">Request Airdrop</h5>
            <IoMdClose
              onClick={() => { setShowConnectWallet(false) }}
              className="mdl_cls_icn"
            />
          </div>

          {steps == 1 &&

            <>
              <Row className="mt-5 py-4">
                <p className="mt-4">Step <span className="text_yellow">1</span><span className="px-1">/</span>3</p>
                <Col>
                  <div
                    className="d-flex flex-column align-items-center"
                    style={{ cursor: "pointer" }}
                  // onClick={() => setShowConnectWallet(false)}

                  >
                    <img onClick={() => isWalletConnected(setWalletAddress)} src={phantom} className="img-fluid mb-2 wallet_img" />

                    {<p onClick={() => isWalletConnected()} className="mb-0 mt-md-2">Phantom</p>}
                  </div>
                </Col>
                {/* <Col>
              <div
                className="d-flex flex-column align-items-center"
                style={{ cursor: "pointer" }}
                onClick={() => setShowConnectWallet(false)}
              >
                <img
                  src={walletconnect}
                  className="img-fluid mb-2 wallet_img"
                />
                <p className="mb-0 mt-1 mt-md-3">Wallet connect</p>
              </div>
            </Col> */}
              </Row>
            </>
          }

          {steps == 2 &&

            <>
              <p className="mt-4">Step <span className="text_yellow">2</span><span className="px-1">/</span>3</p>
              <Row className="align-items-center">
                <Col className="mt-4 col-12 col-md-12">
                  <p className="mb-0">Twitter User Name</p>
                </Col>
                <Col className="mt-4 col-12 col-md-12">
                  <input className="fotm-control form_yell"
                    onChange={(e) => { onchange(e) }}
                    id="Twitter"
                  >

                  </input>
                  <p className="text-danger mt-1">{errorTwitter}</p>
                </Col>
                <Col className="mt-4 col-12 col-md-12">
                  <p className="mb-0">Telegram User Name</p>
                </Col>
                <Col className="mt-4 col-12 col-md-12">
                  <input className="fotm-control form_yell"

                    onChange={(e) => { onchange(e) }}
                    id="Telegram"
                  >

                  </input>
                  <p className="text-danger mt-1">{errorTelegram}</p>
                </Col>
                <Col className="mt-4 col-12 col-md-12">
                  <p className="mb-0">Discord User Name</p>
                </Col>
                <Col className="mt-4 col-12 col-md-12">
                  <input className="fotm-control form_yell"
                    onChange={(e) => { onchange(e) }}
                    id="Discord">

                  </input>
                  <p className="text-danger mt-1">{errorDiscord}</p>
                </Col>
              </Row>
            </>
          }


          {steps == 3 &&

            <>
              <p className="mt-4">Step <span className="text_yellow">3</span><span className="px-1">/</span>3</p>
              <Row>
                <Col className="mt-4 col-12 col-md-12">
                  {/* <p>Choosen Wallet : <span className="text_yellow pl-2">Phantom</span></p> */}
                  <p className="text-center">Click the below icon to follow our page</p>
                  <div className="socialmedia text-center socai_foo">
                    <a
                      href={Sociallink[0]?.content}
                      target="_blank"
                      className="mx-3"
                    >
                      <img src={disc} className="img-fluid media" />
                    </a>
                    <a href={Sociallink[7]?.content} target="_blank" className="mx-3">
                      <img src={fb} className="img-fluid media" />
                    </a>

                    <a href={Sociallink[8]?.content} target="_blank" className="mx-3">
                      <img src={twitter} className="img-fluid media" />
                    </a>
                  </div>
                  {/* <p>Twitter User Name : <span className="text_yellow pl-2">lorem</span></p>
              <p>Telegram User Name : <span className="text_yellow pl-2">lorem</span></p>
              <p>Discord User Name : <span className="text_yellow pl-2">lorem</span></p> */}



                </Col>


              </Row>
            </>
          }


          <div className="text-right btn_secti_right">
            {steps != 1 &&
              <button className="btn themebtn mt-4 mt-lg-0 mr_back" onClick={() => { setSteps(steps - 1) }}>
                Back
              </button>
            }


            {steps == 1 &&
              <button className="btn themebtn mt-4 mt-lg-0"
                disabled={isEmpty(localStorage.Solanawallet) ? true : false}
                onClick={() => { setSteps(steps + 1) }}>
                Next
              </button>
            }


            {steps == 2 &&
              <button className="btn themebtn mt-4 mt-lg-0"

                onClick={() => { userdata() }}>
                Next
              </button>
            }
            {steps == 3 &&
              <button className="btn themebtn mt-4 mt-lg-0" onClick={() => { useralldatas() }}>
                Request Airdrop
              </button>
            }
          </div>

        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConnectWallet;
