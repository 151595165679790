import React, { useEffect, useState } from "react";
import Header from "../Layouts/Header";
import HeaderNew from '../Layouts/HeaderNew'
import Footer from "../Layouts/Footer";
import hand from "../Assets/community/hand.png";
import trans from "../Assets/community/trans.png";
import community from "../Assets/community/community.png";
import growth from "../Assets/community/growth.png";
import Lottie from "lottie-react";
import coin from "../Assets/Json/grow3.json";
import coin1 from "../Assets/Json/fgdgdg.json";
import globe from "../Assets/globe.png";
import inter from "../Assets/Features/inter.png";
import limited from "../Assets/Features/limited.png";
import tokenomics from "../Assets/tokenomics.png";
import futureforward from "../Assets/futureforward.png";

import deploy from "../Assets/contract/deploy.png";
import interfaces from "../Assets/contract/interface.png";
import creation from "../Assets/contract/creation.png";
import point from "../Assets/contract/point.png";
import automated from "../Assets/contract/automated.png";
import instant from "../Assets/contract/instant.png";
import leftvector from "../Assets/contract/leftvector.png";
import rightvector from "../Assets/contract/rightvector.png";
import contractimg from "../Assets/contract/contractsvg.svg";
import dimg from "../Assets/contract/dimg.svg";
import nftjson from "../Assets/Json/bbbb (1).json";
import darkjson from "../Assets/Json/noun11.json";
import darkuniverse from "../Assets/Json/comet.json";
import starss from "../Assets/Json/stars.json";
import arrowvector from "../Assets/arrowvector.png";

import seam from "../Assets/NFT/1 179.png";
import minting from "../Assets/NFT/1 179 (1).png";
import wallets from "../Assets/NFT/1 179 (2).png";
import uiinter from "../Assets/NFT/1 179 (3).png";
import ownership from "../Assets/NFT/1 179 (4).png";
import crosschain from "../Assets/NFT/6 54.png";

import dark1 from "../Assets/darkuniverse/Group 32031.png";
import dark2 from "../Assets/darkuniverse/Group 32031 1.png";
import dark3 from "../Assets/darkuniverse/Group 32032 1.png";

import { FaArrowRightLong } from "react-icons/fa6";
import AOS from "aos";
import "aos/dist/aos.css";

import ContractAddress from "../Components/HomeComponents/ContractAddress";
import OurBlog from "../Components/HomeComponents/OurBlog";
import RoadMap from "../Components/HomeComponents/RoadMap";
import DarkUniverseExchange from "../Components/HomeComponents/DarkUniverseExchange";
import DarkPay from "../Components/HomeComponents/DarkPay";
import axios from "axios";
import configurl from "../../src/config/config";
export default function Home(props) {
  console.log(props, "proso home");
  const [contract, setContract] = useState([
    {
      id: 1,
      image: deploy,
      title: "Democratizing smart Contract Deployment",
      desc: "Dark Universe is committed to democratizing access to blockchain technology. The Zero-Code Smart Contract Deployment feature ensures that even those without extensive coding experience can actively participate in the creation and execution of smart contracts within the Dark Universe ecosystem.",
    },
    {
      id: 2,
      image: interfaces,
      title: "Intuitive User Interface",
      desc: "The zero-code experience is facilitated through an intuitive user interface that guides developers through the process of deploying smart contracts seamlessly.The UI is designed to be user-friendly, eliminating the technical barriers traditionally associated with coding,compiling, and deploying smart contracts.",
    },
    {
      id: 3,
      image: creation,
      title: "Template-Based Contract Creation",
      desc: "Dark Universe’s Zero-Code smart contract deployment leverages templates that encapsulate common functionalities and use cases. Developers can choose from a variety of templates that best suit their needs,customizing and deploying contracts effortlessly without delving into the complexities of coding ",
    },
    {
      id: 4,
      image: point,
      title: "Point-and-Click Functionality",
      desc: "The deployment process is as simple as point-and-click.Developers can select desired contract features, define parameters through an intuitive interface, and deploy contracts with a few clicks. This streamlined approach ensures that the smart contract creation process is accessible to a wider audience.",
    },
    {
      id: 5,
      image: automated,
      title: "Automated Code Generation",
      desc: "Behind the scenes, Dark Universe’s infrastructure automates the code generation process. The system interprets the developer’s choices and preferences,translating them onto the necessary code for the smart contract. This automation ensures accuracy and eliminates the need for manual coding.",
    },
    {
      id: 6,
      image: instant,
      title: "Instant Deployment and Activation",
      desc: "Zero-Code smart contract deployment enables instant deployment and activation. Once the developer finalizes the contract parameters, the system handles the rest, Swiftly deploying the smart contract onto the Dark Universe blockchain. This rapid deployment accelerates the pace of innovation within the ecosystem.",
    },
  ]);

  const [nft, setNft] = useState([
    {
      id: 1,
      image: seam,
      title: "Seamless One-Click Payment Gateway:",
      desc: "Universal NFT Marketplace simplifies the purchase process with a seamless One-Click Payment gateway. Users can swiftly and securely acquire NFTs with a single click, streamlining the transaction experience for both buyers and sellers.",
    },
    {
      id: 2,
      image: minting,
      title: "NFT Minting Made Effortless",
      desc: "Empowering artists and creators, Universal NFT Marketplace facilitates NFT Minting with unparalleled ease. A user-friendly interface guides creators through the process, allowing them to transform their digital masterpieces into unique and tradeable NFTs effortlessly.",
    },
    {
      id: 3,
      image: wallets,
      title: "Wallet Integrations for Accessibility",
      desc: "Recognizing the importance of accessibility, Universal NFT Marketplace seamlessly integrates with various wallets. Users can connect their preferred wallets,ensuring a secure and personalized experience while interacting with the platform. ",
    },
    {
      id: 4,
      image: uiinter,
      title: "World-Class User Interface (UI):",
      desc: "The Universal NFT Marketplace boasts a world-class UI design. The visually appealing and intuitive interface ensures that users can navigate the platform with ease, enhancing the overall experience for both creators and collectors.",
    },
    {
      id: 5,
      image: ownership,
      title: "Decentralized Ownership",
      desc: "Universal NFT Marketplace is built on the principles of decentralization. The blockchain infrastructure ensures that NFT ownership is transparent, secure,and verifiable. Each NFT represents a unique piece of digital art or collectible with a clear and immutable chain of ownership.",
    },
    {
      id: 6,
      image: crosschain,
      title: "Cross-Chain Compatibility",
      desc: "Recognizing the diverse landscape of blockchain ecosystems, Universal NFT Marketplace is designed with cross-chain compatibility. This enables users to trade NFTs seamlessly across different blockchain networks, expanding the reach and liquidity of the marketplace.",
    },
  ]);

  const [cmsdata, setCmsdata] = useState([]);
  const [cmsSmartContract, setSmartContract] = useState([]);
  const [cmsNFT, setNFT] = useState([]);
  const [DrakMetaverse, setDrakMetaverse] = useState([]);
  const [Cmsfeatures, setCmsfeatures] = useState([]);
  const [screenWidth, setScreenWidth] = useState(false);
  const [path, setPath] = useState("");
  const [timescreen, setTimescreen] = useState(1000);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      console.log("updating height");
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  console.log("Cmsfeatures_Cmsfeatures", Cmsfeatures);

  const getCmsFeatures = async () => {
    axios.get(configurl.backurl + "/api/GetCmsFeatures").then((res) => {
      setCmsfeatures(res.data);
    });
  };

  const getAlldata = async () => {
    axios.get(configurl.backurl + "/api/GetCmsData").then((res) => {
      console.log("responsedatafwefewfw---", res.data);
      setCmsdata(res.data);
    });
  };

  const getcmsSmartdata = async () => {
    axios.get(configurl.backurl + "/api/GetCmsSmartcontrac").then((res) => {
      setSmartContract(res.data);
    });
  };

  const getcmsNFT = async () => {
    axios.get(configurl.backurl + "/api/GetCmsNFT").then((res) => {
      setNFT(res.data);
    });
  };

  const getDrakMetaversedata = async () => {
    axios.get(configurl.backurl + "/api/GetDarkMetaverse").then((res) => {
      setDrakMetaverse(res.data);
    });
  };

  const getImagedata = async () => {
    axios.get(configurl.backurl + "/api/GetCmsimage").then((res) => {
      console.log("response_imagedata", res.data);
      // set_records(res.data)
    });
  };

  console.log("cmsdata_cmsdata", cmsdata);
  useEffect(() => {
    console.log("screenwidth", window.innerWidth);
    if (width < 992) {
      setTimescreen(4000);
    } else {
      setTimescreen(1200);
    }
  }, []);

  useEffect(() => {
    setPath(window.location.pathname);

    console.log("inside home");
    console.log(window.location.pathname, "pathname");
    if (window.location.pathname == "/home/tokenomics") {
      setTimeout(() => {
        console.log("inside token");
        window.scrollTo(0, document.getElementById("tokenomics").offsetTop);
      }, timescreen);

      // window.scrollTo(0,document.getElementById("tokenomics").clientTop);
    } else if (window.location.pathname == "/home/metaverse") {
      setTimeout(() => {
        console.log("inside metaverse");
        window.scrollTo(0, document.getElementById("metaverse").offsetTop);
      }, timescreen);

      // window.scrollTo(0,document.getElementById("tokenomics").clientTop);
    }
  }, [props]);

  useEffect(() => {
    AOS.init({});

    getAlldata();
    getCmsFeatures();

    getcmsSmartdata();
    getcmsNFT();
    getDrakMetaversedata();
    getImagedata();
  }, []);

  return (
    <>
      <Header />
      {/* <HeaderNew/> */}
      {cmsdata && cmsdata?.length > 0 ? (
        <div className="Homepage pt-5 pb-5">
          <div className="container pt-5">
            <div className="darkuniverse">
              <Lottie
                className="darkuniverselottie"
                animationData={darkuniverse}
                loop={true}
              />
              <Lottie
                className="darkuniverselottie1"
                animationData={darkuniverse}
                loop={true}
              />
              <Lottie className="starss" animationData={starss} loop={true} />
              <div className="row pb-5">
                <div className="col-md-8 col-lg-6 mx-auto text-center">
                  <h1 className="heading">{`${
                    cmsdata[0]?.identifier == "Landing" ? (
                      cmsdata[0]?.content
                    ) : (
                      ""
                    )
                  }`}</h1>
                  <p className="subheading">
                    {`${
                      cmsdata[1]?.identifier == "Landing" ? (
                        cmsdata[1]?.content
                      ) : (
                        ""
                      )
                    }`}
                  </p>
                </div>
                <div className="col-md-10 mx-auto">
                  <img
                    src={globe}
                    className="img-fluid"
                    data-aos="fade-up"
                    data-aos-duration="2500"
                  />
                </div>
              </div>
              <div className="row pt-5 pb-3 pb-md-4">
                <div className="col-md-11 col-lg-9 mx-auto text-center">
                  <h1 className="heading1 mb-3">{`${
                    cmsdata[2]?.identifier == "Landing" ? (
                      cmsdata[2]?.content
                    ) : (
                      ""
                    )
                  }`}</h1>
                  <p className="subheading">
                    {`${
                      cmsdata[3]?.identifier == "Landing" ? (
                        cmsdata[3]?.content
                      ) : (
                        ""
                      )
                    }`}
                  </p>
                </div>
              </div>
            </div>

            <div className="row community section2 align-items-center pt-4 pb-5">
              <Lottie
                className="darkjsoncom"
                animationData={darkuniverse}
                loop={true}
              />
              <div className="col-sm-8 col-md-12 col-lg-10 col-xl-7 growth">
                <div className="row">
                  <div className="col-md-5">
                    <div className="card">
                      <img src={hand} className="img-fluid commuimg" />
                      <h5 className="mt-3">{`${
                        cmsdata[4]?.identifier == "Landing" ? (
                          cmsdata[4]?.content
                        ) : (
                          ""
                        )
                      }`}</h5>
                      <p className="subheading">
                        {`${
                          cmsdata[5]?.identifier == "Landing" ? (
                            cmsdata[5]?.content
                          ) : (
                            ""
                          )
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 pt-5">
                    <div className="card">
                      <img src={trans} className="img-fluid commuimg" />
                      <h5 className="mt-3">
                        {" "}
                        {`${
                          cmsdata[6]?.identifier == "Landing" ? (
                            cmsdata[6]?.content
                          ) : (
                            ""
                          )
                        }`}
                      </h5>
                      <p className="subheading">
                        {`${
                          cmsdata[8]?.identifier == "Landing" ? (
                            cmsdata[8]?.content
                          ) : (
                            ""
                          )
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 pt-5 pt-md-0 mt-3">
                    <div className="card">
                      <img src={community} className="img-fluid commuimg" />
                      <h5 className="mt-3">{`${
                        cmsdata[9]?.identifier == "Landing" ? (
                          cmsdata[9]?.content
                        ) : (
                          ""
                        )
                      }`}</h5>
                      <p className="subheading">
                        {`${
                          cmsdata[10]?.identifier == "Landing" ? (
                            cmsdata[10]?.content
                          ) : (
                            ""
                          )
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 pt-5">
                    <div className="card mt-4">
                      <img src={growth} className="img-fluid commuimg" />
                      <h5 className="mt-3">{`${
                        cmsdata[11]?.identifier == "Landing" ? (
                          cmsdata[11]?.content
                        ) : (
                          ""
                        )
                      }`}</h5>
                      <p className="subheading">
                        {`${
                          cmsdata[12]?.identifier == "Landing" ? (
                            cmsdata[12]?.content
                          ) : (
                            ""
                          )
                        }`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8 col-lg-7 col-xl-5 mx-auto mt-4">
                <Lottie
                  className="growthjson"
                  animationData={coin}
                  loop={true}
                />
              </div>
            </div>

            <div className="row pt-4 pb-4">
              <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3 mx-auto">
                <div className="titleback">
                  <h5>
                    {`${
                      Cmsfeatures[0]?.identifier == "Features" ? (
                        Cmsfeatures[0]?.content
                      ) : (
                        ""
                      )
                    }`}
                  </h5>
                </div>
              </div>
            </div>
            <div className="row smart pt-4 pb-5">
              <div className="col-md-11 col-lg-12 col-xl-10 mx-auto">
                <div className="row">
                  <div className="col-lg-6 mt-3">
                    <div className="cardborder">
                      <img src={inter} className="img-fluid featureimg" />
                      <h5 className="mt-4">
                        {`${
                          Cmsfeatures[1]?.identifier == "Features" ? (
                            Cmsfeatures[1]?.content
                          ) : (
                            ""
                          )
                        }`}
                      </h5>
                      <p className="subheading mb-0">
                        {`${
                          Cmsfeatures[2]?.identifier == "Features" ? (
                            Cmsfeatures[2]?.content
                          ) : (
                            ""
                          )
                        }`}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-6 mt-3">
                    <div className="cardborder">
                      <img src={limited} className="img-fluid featureimg" />
                      <h5 className="mt-4">{`${
                        Cmsfeatures[3]?.identifier == "Features" ? (
                          Cmsfeatures[3]?.content
                        ) : (
                          ""
                        )
                      }`}</h5>
                      <p className="subheading mb-0">
                        {`${
                          Cmsfeatures[4]?.identifier == "Features" ? (
                            Cmsfeatures[4]?.content
                          ) : (
                            ""
                          )
                        }`}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-5 pb-5 align-items-center">
              <div className="col-md-6 text-center mt-3 position-relative">
                <img src={futureforward} className="img-fluid" />
                <Lottie
                  className="round round1"
                  animationData={coin1}
                  loop={true}
                />
              </div>
              <div className="col-md-6 mt-3">
                <h1 className="heading1 mb-3">{`${
                  Cmsfeatures[5]?.identifier == "Features" ? (
                    Cmsfeatures[5]?.content
                  ) : (
                    ""
                  )
                }`}</h1>
                <p className="subheading mt-3 mb-0">
                  {" "}
                  {`${
                    Cmsfeatures[6]?.identifier == "Features" ? (
                      Cmsfeatures[6]?.content
                    ) : (
                      ""
                    )
                  }`}{" "}
                </p>
              </div>
            </div>

            <div className="tokenomics" name="tokenomics" id="tokenomics">
              <Lottie
                className="darkjson1"
                animationData={darkuniverse}
                loop={true}
              />
              <div className="row pt-5 pb-4">
                <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3 mx-auto">
                  <div className="titleback">
                    <h5>{`${
                      Cmsfeatures[7]?.identifier == "Features" ? (
                        Cmsfeatures[7]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                  </div>
                </div>
                <p className="subheading subtext text-center mt-4">
                  {`${
                    Cmsfeatures[8]?.identifier == "Features" ? (
                      Cmsfeatures[8]?.content
                    ) : (
                      ""
                    )
                  }`}
                </p>
              </div>
              <div className="row pt-3 pt-sm-5 pb-5">
                <div className="col-lg-10 col-xl-9 mx-auto position-relative">
                  <img src={tokenomics} className="img-fluid" />
                  <Lottie
                    className="round roundss"
                    animationData={coin1}
                    loop={true}
                  />
                </div>
              </div>
            </div>

            <div className="smart">
              <Lottie
                className="darkjson1"
                animationData={darkuniverse}
                loop={true}
              />
              <Lottie
                className="darkjson2"
                animationData={darkuniverse}
                loop={true}
              />
              <Lottie
                className="darkjson2"
                animationData={darkuniverse}
                loop={true}
              />
              <div className="row pt-5 pb-3">
                <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3 mx-auto">
                  <div className="titleback">
                    <h5>{`${
                      cmsSmartContract[0]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[0]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                  </div>
                </div>
              </div>
              <div className="row pt-3 pb-3 pb-md-4">
                <div className="col-md-11 col-lg-9 mx-auto text-center">
                  <h1 className="heading1 mb-3">{`${
                    cmsSmartContract[1]?.identifier ==
                    "Smart Contracts" ? (
                      cmsSmartContract[1]?.content
                    ) : (
                      ""
                    )
                  }`}</h1>
                  <p className="subheading">
                    {`${
                      cmsSmartContract[2]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[2]?.content
                      ) : (
                        ""
                      )
                    }`}
                  </p>
                </div>
              </div>
              <div className="row pt-3 pt-sm-4 pb-5">
                <div className="col-lg-10 col-xl-7 position-relative mx-auto">
                  <img src={contractimg} className="img-fluid" alt="image" />

                  <img src={dimg} className="dimg" alt="image" />
                  <Lottie
                    className="img-fluid svgcontract"
                    animationData={coin1}
                    loop={true}
                  />
                </div>
              </div>
              <div className="row pt-3 pt-sm-4 pb-5">
                {console.log("contarct_imag", contract)}
                {/* {contract.map((e, i) => (         
                <div className="col-md-6 col-lg-4 mt-5">
                  <div className="contractborder">
                    <img
                      src={e.image}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{e.title}</h5>
                    <p className="subheading mb-0">{e.desc}</p>
                  </div>
                </div>
              ))} */}
                <div className="col-md-6 col-lg-4 mt-5">
                  <div className="contractborder">
                    <img
                      src={deploy}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsSmartContract[3]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[3]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading mb-0">{`${
                      cmsSmartContract[4]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[4]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mt-5">
                  <div className="contractborder">
                    <img
                      src={interfaces}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsSmartContract[5]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[5]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading mb-0">{`${
                      cmsSmartContract[6]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[6]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mt-5">
                  <div className="contractborder">
                    <img
                      src={creation}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsSmartContract[7]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[7]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading mb-0">{`${
                      cmsSmartContract[8]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[8]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mt-5">
                  <div className="contractborder">
                    <img
                      src={point}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsSmartContract[9]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[9]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading mb-0">{`${
                      cmsSmartContract[10]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[10]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mt-5">
                  <div className="contractborder">
                    <img
                      src={automated}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsSmartContract[11]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[11]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading mb-0">{`${
                      cmsSmartContract[12]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[12]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4 mt-5">
                  <div className="contractborder">
                    <img
                      src={instant}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsSmartContract[13]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[13]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading mb-0">{`${
                      cmsSmartContract[14]?.identifier == "Smart Contracts" ? (
                        cmsSmartContract[14]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                  </div>
                </div>
              </div>
              {/* <div className="row pt-3 pb-3 position-relative">
                <img src={leftvector} className="leftvector" alt="image" />
                <img src={rightvector} className="rightvector" alt="image" />
                <div className="col-10 col-sm-5 col-md-3 col-lg-2 mx-auto">
                  <button className="btn themebtn w-100">See all</button>
                </div>
              </div> */}
            </div>

            <div className="nft pt-5" name="metaverse" id="metaverse">
              <Lottie
                className="darkjson1"
                animationData={darkuniverse}
                loop={true}
              />
              <Lottie
                className="darkjson11"
                animationData={darkuniverse}
                loop={true}
              />
              <Lottie
                className="darkjson2"
                animationData={darkuniverse}
                loop={true}
              />
              <div className="row pt-5 pb-3">
                <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3 mx-auto">
                  <div className="titleback">
                    <h5>{`${
                      cmsNFT[0]?.identifier == "NFT" ? (
                        cmsNFT[0]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                  </div>
                </div>
              </div>
              <div className="row pt-3 pb-3 pb-md-4">
                <div className="col-md-11 col-lg-9 mx-auto text-center">
                  <h1 className="heading1 mb-3">{`${
                    cmsNFT[1]?.identifier == "NFT" ? cmsNFT[1]?.content : <></>
                  }`}</h1>
                  <p className="subheading">
                    {`${
                      cmsNFT[2]?.identifier == "NFT" ? (
                        cmsNFT[2]?.content
                      ) : (
                        ""
                      )
                    }`}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-xl-10 mx-auto nftposition">
                  <Lottie
                    className="img-fluid nftjson"
                    animationData={nftjson}
                    loop={true}
                  />
                </div>
              </div>
              <div className="row pt-4 pb-5">
                {/* {nft.map((e, i) => (
                <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
                  <div className="nftborder">
                    <img
                      src={e.image}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{e.title}</h5>
                    <p className="subheading nftsubhead mb-0">{e.desc}</p>
                    <img src={arrowvector} className="img-fluid arrowimg" />
                    <FaArrowRightLong className="faarrow" />
                  </div>
                </div>
              ))} */}
                <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
                  <div className="nftborder">
                    <img
                      src={seam}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsNFT[3]?.identifier == "NFT" ? (
                        cmsNFT[3]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">{`${
                      cmsNFT[4]?.identifier == "NFT" ? (
                        cmsNFT[4]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                    <img src={arrowvector} className="img-fluid arrowimg" />
                    <FaArrowRightLong className="faarrow" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
                  <div className="nftborder">
                    <img
                      src={minting}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsNFT[5]?.identifier == "NFT" ? (
                        cmsNFT[5]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">{`${
                      cmsNFT[6]?.identifier == "NFT" ? (
                        cmsNFT[6]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                    <img src={arrowvector} className="img-fluid arrowimg" />
                    <FaArrowRightLong className="faarrow" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
                  <div className="nftborder">
                    <img
                      src={wallets}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsNFT[7]?.identifier == "NFT" ? (
                        cmsNFT[7]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">{`${
                      cmsNFT[8]?.identifier == "NFT" ? (
                        cmsNFT[8]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                    <img src={arrowvector} className="img-fluid arrowimg" />
                    <FaArrowRightLong className="faarrow" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
                  <div className="nftborder">
                    <img
                      src={uiinter}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsNFT[9]?.identifier == "NFT" ? (
                        cmsNFT[9]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">{`${
                      cmsNFT[10]?.identifier == "NFT" ? (
                        cmsNFT[10]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                    <img src={arrowvector} className="img-fluid arrowimg" />
                    <FaArrowRightLong className="faarrow" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
                  <div className="nftborder">
                    <img
                      src={ownership}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsNFT[11]?.identifier == "NFT" ? (
                        cmsNFT[11]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">{`${
                      cmsNFT[12]?.identifier == "NFT" ? (
                        cmsNFT[12]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                    <img src={arrowvector} className="img-fluid arrowimg" />
                    <FaArrowRightLong className="faarrow" />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-xl-4 mt-5">
                  <div className="nftborder">
                    <img
                      src={crosschain}
                      className="img-fluid featureimg contractimg"
                    />
                    <h5 className="mt-4">{`${
                      cmsNFT[13]?.identifier == "NFT" ? (
                        cmsNFT[13]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">{`${
                      cmsNFT[14]?.identifier == "NFT" ? (
                        cmsNFT[14]?.content
                      ) : (
                        ""
                      )
                    }`}</p>
                    <img src={arrowvector} className="img-fluid arrowimg" />
                    <FaArrowRightLong className="faarrow" />
                  </div>
                </div>
              </div>
              {/* <div className="row pt-3 pb-3 position-relative">
                <img src={leftvector} className="leftvector" alt="image" />
                <img src={rightvector} className="rightvector" alt="image" />
                <div className="col-10 col-sm-5 col-md-3 col-lg-2 mx-auto">
                  <button className="btn themebtn w-100">See all</button>
                </div>
              </div> */}
            </div>

            <div className="nft pt-5">
              <div className="row pt-5 pb-3">
                <div className="col-11 col-sm-8 col-md-5 col-lg-4 col-xl-3 mx-auto">
                  <div className="titleback">
                    <h5>{`${
                      DrakMetaverse[0]?.identifier == "Dark_Metaverse" ? (
                        DrakMetaverse[0]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                  </div>
                </div>
              </div>
              <div className="row pt-3 pb-3 pb-md-4">
                <div className="col-md-11 col-lg-9 mx-auto text-center">
                  <h1 className="heading1 mb-3">{`${
                    DrakMetaverse[1]?.identifier == "Dark_Metaverse" ? (
                      DrakMetaverse[1]?.content
                    ) : (
                      ""
                    )
                  }`}</h1>
                  <p className="subheading">
                    {`${
                      DrakMetaverse[2]?.identifier == "Dark_Metaverse" ? (
                        DrakMetaverse[2]?.content
                      ) : (
                        ""
                      )
                    }`}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-7 col-md-8 col-lg-6 col-xl-5 mx-auto">
                  <Lottie
                    className="img-fluid"
                    animationData={darkjson}
                    loop={true}
                  />
                </div>
              </div>

              <div className="row justify-content-center dark pt-4 pb-5">
                <div className="col-sm-10 col-md-6 col-lg-5 col-xl-4 mt-4 mt-md-5 mt-xl-4">
                  <div className="card">
                    <img
                      src={dark1}
                      className="img-fluid featureimg contractimg darkimg darkborder"
                    />
                    <h5 className="pt-4 mt-5">{`${
                      DrakMetaverse[3]?.identifier == "Dark_Metaverse" ? (
                        DrakMetaverse[3]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">
                      {`${
                        DrakMetaverse[4]?.identifier == "Dark_Metaverse" ? (
                          DrakMetaverse[4]?.content
                        ) : (
                          ""
                        )
                      }`}
                    </p>
                  </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-5 col-xl-4 mt-4 mt-md-5 mt-xl-4">
                  <div className="card">
                    <img
                      src={dark2}
                      className="img-fluid featureimg contractimg darkimg darkborder"
                    />
                    <h5 className="pt-4 mt-5">{`${
                      DrakMetaverse[5]?.identifier == "Dark_Metaverse" ? (
                        DrakMetaverse[5]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">
                      {`${
                        DrakMetaverse[6]?.identifier == "Dark_Metaverse" ? (
                          DrakMetaverse[6]?.content
                        ) : (
                          ""
                        )
                      }`}
                    </p>
                  </div>
                </div>
                <div className="col-sm-10 col-md-6 col-lg-5 col-xl-4 mt-4 mt-md-5 mt-xl-4">
                  <div className="card">
                    <img
                      src={dark3}
                      className="img-fluid featureimg contractimg darkimg darkborder"
                    />
                    <h5 className="pt-4 mt-5">{`${
                      DrakMetaverse[7]?.identifier == "Dark_Metaverse" ? (
                        DrakMetaverse[7]?.content
                      ) : (
                        ""
                      )
                    }`}</h5>
                    <p className="subheading nftsubhead mb-0">
                      {`${
                        DrakMetaverse[8]?.identifier == "Dark_Metaverse" ? (
                          DrakMetaverse[8]?.content
                        ) : (
                          ""
                        )
                      }`}
                    </p>
                  </div>
                </div>
              </div>

              {/* <div className="row pt-4 pb-3 position-relative">
                <img src={leftvector} className="leftvector" alt="image" />
                <img src={rightvector} className="rightvector" alt="image" />
                <div className="col-10 col-sm-5 col-md-3 col-lg-2 mx-auto">
                  <button className="btn themebtn w-100">See all</button>
                </div>
              </div> */}
            </div>
          </div>

          <DarkPay />
          <DarkUniverseExchange />
          <RoadMap />
          {/* <OurBlog /> */}
          <ContractAddress />
        </div>
      ) : (
        <></>
      )}
      <Footer />
    </>
  );
}