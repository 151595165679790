import React from "react";
import  { useEffect, useState } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import axios from "axios";
import configurl from "../../config/config"
const ContractAddress = () => {
  const [email, setEmail] = useState("")
  const [error, setError] = useState("")
  console.log("emails",email);



const Emaildata = async ()=>{
  var data ={
    email : email
  }
console.log("dataaaemail",email);
  let useremail = await axios.post(configurl.backurl + "/api/subscriberFormat", data)
}


const submit = async() => {
  let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
   console.log("emailRegex.test(email)",emailRegex.test(email));   
   if (email == "" || email == undefined || email == null) {
     setError("REQUIRED")
   } else if (!(emailRegex.test(email))) {
     setError("Invalid email")
   }else{
     setError("")
     const mail = await axios.post(configurl.backurl + "/api/subscriberFormat", {email : email.toLocaleLowerCase()});
     if(mail.data.status){
       toast.success(`${mail.data.message}`, {
         style: {
           minWidth: '300px',
           minHeight: '55px'
         }
       })
     }
   }
 }


  return (
    <>
      <section className="hm_contract pt-5">
        <div className="hm_contract_banner  py-5">
          <Container className="container_90">
            <Row className="justify-content-center">
              <Col xl={11} xxl={10}>
                <div>
                  <h1 className="hm_lnr_grd_txt text-uppercase text-center mb-0">
                    Darkcoin
                  </h1>
                  <h3 className="text-center h3_fw_300">Contract Address</h3>
                  <Row className="mx-auto mt-4 mt-sm-5">
                    <Col
                      md={9}
                      lg={9}
                      className="ps-0 d-flex align-items-center"
                    >
                      <p className="hm_cntct_id mb-0">
                        Contract Address (SOLANA) :
                        4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB
                      </p>
                    </Col>
                    <Col
                      md={3}
                      lg={3}
                      className="px-0 mt-4 mt-md-0 d-flex justify-content-start justify-content-md-end justify-content-xxl-center align-items-center"
                    >
                      <CopyToClipboard
                        text={"0x573765d4F365a1a2C7891883bc0C9c35C4c6fD16"}
                        onCopy={() =>
                          toast("Address Copied", {
                            autoClose: 1000,
                            type: "warning",
                          })
                        }
                        
                      > 
                      <button className="hm_cpy_btn">Copy Address</button>
                      </CopyToClipboard>
                    </Col>
                    <Col
                      md={9}
                      lg={9}
                      className="ps-0 d-flex align-items-center mt-4"
                    >
                      <p className="hm_cntct_id mb-0">
                        Contract Address ( ETH ) :
                        0x573765d4F365a1a2C7891883bc0C9c35C4c6fD16
                      </p>
                    </Col>
                    <Col
                      md={3}
                      lg={3}
                      className="px-0 mt-4  d-flex justify-content-start justify-content-md-end justify-content-xxl-center align-items-center "
                    >
                      <CopyToClipboard
                        text={"4JWktLr39ZbyutVnym7wKxV1RDoJz7ifDYxMUHDmAbPB"}
                        onCopy={() =>
                          toast("Address Copied", {
                            autoClose: 1000,
                            type: "warning",
                          })
                        }
                        
                      > 
                      <button className="hm_cpy_btn">Copy Address</button>
                      </CopyToClipboard>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>{" "}
          </Container>
        </div>
        <Container className="container_90">
          <Row className="justify-content-center pt-4  pt-xl-5">
            <Col xl={6}>
              <div>
                <h5 className="hm_hds_wht text-center">Get our newsletter</h5>
                <div className="hm_inpt_wrp mx-auto ">
                  <Row className="mx-auto h-100">
                    <Col xs={9} sm={8} xxl={7} className="h-100">
                      <input type="text" placeholder="Enter your mail id" 
                        onChange={(e) => { setEmail(e.target.value) }}
                        />
                    </Col>
                    <Col
                      xs={3}
                      sm={4}
                      xxl={5}
                      className="h-100 d-flex align-items-center"
                    >
                      <FaArrowRightLong />
                    </Col>
                  </Row>
                  <p className="text-danger mt-1">{error}</p>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="hm_submit_btn "  onClick={() => submit()}>Submit</button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContractAddress;
