import { 
    clusterApiUrl, 
    Connection, 
    PublicKey, 
    Keypair, 
  
} from '@solana/web3.js';
import { 
    getOrCreateAssociatedTokenAccount, 
    transfer, 
} from '@solana/spl-token';
import * as bs58 from 'bs58';
import toast from 'react-hot-toast';
import axios from "axios";
import configurl from '../config/config';
import tokenaddress from "../config/config"


 
// mainnet-beta
// devnet
// testnet
const connection = new Connection(clusterApiUrl('mainnet-beta'), 'confirmed');

  export const isWalletConnected = async (setWalletAddress) => {
   
    try {
      console.log("solana_sdsads");
      const { solana } = window;
      console.log("solana___",solana);
      if (solana) {
        if (solana.isPhantom) {
          console.log("phantom wallet found");
          // When using this flag, Phantom will only connect and emit a connect event if the application is trusted. Therefore, this can be safely called on page load for new users, as they won't be bothered by a pop-up window even if they have never connected to Phantom before.
          // if user already connected, { onlyIfTrusted: true }
          const response = await solana.connect({ onlyIfTrusted: false });
          console.log(
            "public key",
            response.publicKey.toString()
          );
          setWalletAddress(response.publicKey.toString())
            localStorage.setItem("Solanawallet",response.publicKey.toString())
            if(localStorage.Solanawallet){
          toast.success(`${"wallet connected"}`, {
            style: {
                minWidth: '300px',
                minHeight: '55px'
            }
        })
            }


      
        } else {
          toast.error("Please install phantom wallet");
        }
      }
    } catch (error) {
      console.log(error,"error");
    }
  };

  export const UseAdminSolPrivateKey = async () => {
    var adminPrivateKey = "u6fafWyUAmwpzww4ensFUXxmffTxA6P6Pvccmb9YkRJGHNKPoGmcYkeC9n7BYiqLBuXN35tJLHYCv6cX2wr2P8N"
    return adminPrivateKey;

  }

const UseSolPrivateKeyDB= async()=> {
    const adminPrivateKey = await UseAdminSolPrivateKey();
    const privateKeyByteArray = bs58.decode(adminPrivateKey);//Private Key account1
    const WALLET_SECRET_KEY = new Uint8Array(privateKeyByteArray);
    const fromWallet = Keypair.fromSecretKey(WALLET_SECRET_KEY);
    return {
        adminPrivateKey: adminPrivateKey,
        privateKeyByteArray: privateKeyByteArray,
        WALLET_SECRET_KEY: WALLET_SECRET_KEY,
        fromWallet: fromWallet
    }
}
 



export const tokenBalance = async(tokenAddress)=>{

    try{
        const { fromWallet } = await UseSolPrivateKeyDB();
       
        var tokenAddress = new PublicKey(tokenAddress.toString());
        const escrowTokenAccount =await getOrCreateAssociatedTokenAccount(
         connection,
         fromWallet,
         tokenAddress,
         fromWallet.publicKey
     );
     
     let tokenAccountBalance = await connection.getTokenAccountBalance(escrowTokenAccount.address);
     return tokenAccountBalance?.value?.uiAmount;
    }catch(e){
        console.log("e>>>",e);
    }
      
    }
    
  
//TransferUsertoAdmin

export const UseAdminTransferSOLtokens = async(token,to,amount) => {
    console.log("token_data",token,to,amount);
    try{
    const { fromWallet } = await UseSolPrivateKeyDB();
    var tokenAddress = new PublicKey(token);
    var toWallet = new PublicKey(to);

  
      // if(localStorage.getItem("Solanawallet")){
      //   let ceck =  await axios.post(configurl.backurl + "/api/userinfromation",{Address:localStorage.getItem("Solanawallet")})
      //   if(!ceck.data.status){
      //     return false
      //   }
      // }
    // var toWallet = (await getaddress(toKey)).fromWallet;
    // Get the token account of the fromWallet address, and if it does not exist, create it
    const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
        connection,
        fromWallet,
        tokenAddress,
        fromWallet.publicKey
    );
 
    console.log(`from Token Account: ${fromTokenAccount.address.toBase58()}`);
    // Get the token account of the toWallet address, and if it does not exist, create it
     const toTokenAccount = await getOrCreateAssociatedTokenAccount(connection, fromWallet, tokenAddress, toWallet);
    console.log(`toTokenAccount ${toTokenAccount.address.toBase58()}`);
    
   
    const signature = await transfer(
        connection,
        fromWallet,
        fromTokenAccount.address,
        toTokenAccount.address,
        fromWallet.publicKey,
       toFixedNumber(amount * 10 ** 9)
    );
    // let data ={
    //   Address:localStorage.getItem("Solanawallet"),
    //   status:true,
    //   amount:amount 
    // }
    // let newEntry =  await axios.post(configurl.backurl + "/api/newUser",data)


     
    console.log(`finished transfer with ${signature}`);

        return { success: true, signature: signature }
    }
    catch(e){
        console.log("eeeeee",e);
        return { success: false, message: e }
    }
}



export const toFixedNumber = (x)=> {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}