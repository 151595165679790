import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Homepage from "./Pages/Home";
import Airdroppage from "./Pages/Airdrops";
import Tokenomics from "./Pages/Tokenomics";
import Metaverse from "./Pages/Metaverse";
import "./App.css";

export default function App() {
  return (
    <>
      <BrowserRouter>
     
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/airdrops" element={<Airdroppage />} />
          <Route
            path="/home/tokenomics"
            element={<Homepage scrollto="tokenomics" />}
          />
          <Route
            path="/home/metaverse"
            element={<Homepage scrollto="metaverse" />}
          />
        </Routes>
      </BrowserRouter>
     
    </>
  );
}
