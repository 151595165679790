import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  NavDropdown,
  Nav,
  Navbar,
  Offcanvas,
  Form,
} from "react-bootstrap";
import logo from "../Assets/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import axios from "axios";
import configurl from "../../src/config/config";

export default function Header() {
  const [CmsImage, setCmsImage] = useState([]);
  const [checkPath, setCheckPath] = useState(true);

  const [menuOpen, setMenuOpen] = useState(false);  // initially closed

  const toggleMenu = () => {  
// alert(1)
    const getWindowWidth= window.innerWidth
    console.log("windn",getWindowWidth, menuOpen)
    if(getWindowWidth<992){
      
      setMenuOpen(false)
    }else{setMenuOpen(false)}
    
    // this handler is "regular"
      // setMenuOpen(!menuOpen);    // open and close...
  };



  
  const handleClose = () => setMenuOpen(false)


  const getLocation = useLocation();
  console.log("getlocation", getLocation.pathname);

  useEffect(() => {
    console.log("urlpath", getLocation);
    if (getLocation.pathname == "/") {
      setCheckPath(true);
      console.log("checkk", checkPath);
    } else {
      setCheckPath(false);
      console.log("checkk", checkPath);
    }
  }, [getLocation]);

  const getImagedata = async () => {
    axios.get(configurl.backurl + "/api/GetCmsimage").then((res) => {
      setCmsImage(res.data);
    });
  };

  // const handleClose = () => {
  //   const closer = document.getElementsByClassName("btn-close");
  //   closer.click();
  // };

  useEffect(() => {
    getImagedata();
  }, []);
  console.log("checkk", checkPath);

  return (
    <>
      <Navbar key="lg" expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={`${configurl.backurl}/CmsImage/${CmsImage[0]?.image}`}
              className="img-fluid logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} onClick={()=>setMenuOpen(!menuOpen)} />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="end"
            restoreFocus={false}
      show={menuOpen}
      onHide={()=>setMenuOpen(!menuOpen)}
      // onHide={()=>handleClose}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <img src={logo} className="img-fluid logo" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav className="justify-content-center flex-grow-1 pe-3">
                <NavLink to="/" onClick={()=>toggleMenu()}>Home</NavLink>
                {/* <NavLink  to="/airdrops">Airdrop</NavLink> */}
                <NavLink to="/airdrops" onClick={()=>toggleMenu()}>Airdrop</NavLink>
                {/* <NavLink  to="/tokenomics">Tokenomics</NavLink> */}
                {/* <Link
                  to="tokenomics"
                  spy={true}
                  smooth={true}
                  offset={35}
                  duration={100}
                >
                  dummy
                </Link> */}

                {checkPath ? (
                  <Link
                    to="tokenomics"
                    spy={true}
                    smooth={true}
                    offset={35}
                    duration={100}
                    onClick={()=>toggleMenu()}
                  >
                    Tokenomics
                  </Link>
                ) : (
                  <NavLink to="/home/tokenomics" goto="tokenomics" onClick={()=>toggleMenu()}>
                    Tokenomics
                  </NavLink>
                )}
                {checkPath ? (
                  <Link
                    to="metaverse"
                    spy={true}
                    smooth={true}
                    offset={55}
                    duration={100}
                    onClick={()=>toggleMenu()}
                  >
                    Metaverse
                  </Link>
                ) : (
                  <NavLink to="/home/metaverse" goto="metaverse" onClick={()=>toggleMenu()}>
                    Metaverse
                  </NavLink>
                )}

                {/* <Link
                  to="tokenomics"
                  spy={true}
                  smooth={true}
                  offset={35}
                  duration={100}
                >
                  Tokenomics
                </Link>
                <Link
                  to="metaverse"
                  spy={true}
                  smooth={true}
                  offset={55}
                  duration={100}
                >
                  Metaverse
                </Link>
                {/* <NavLink to="/metaverse">Metaverse</NavLink> */}
              </Nav>
              <button className="btn themebtn mt-4 mt-lg-0">
                <a
                  href="https://drive.google.com/file/d/1qK_Y-ODjRhqUDhYoyI49jYylimiYEtcR/view"
                  className="text-decoration-none"
                  target="blank"
                >
                  Read Darkpaper
                </a>
              </button>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
}
